.App {
  text-align: center;
}

.content{
  margin: 1em;
}








  

